import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/Layout.tsx";
import PageTitle from "../components/PageTitle";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageTitle showTagsLink={false} mdxType="PageTitle">About</PageTitle>
    <div className="page">
個人的な備忘録・ナレッジベースとして使っている技術ブログです。
      <h2 {...{
        "id": "このブログについて",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h2" {...{
          "href": "#%E3%81%93%E3%81%AE%E3%83%96%E3%83%AD%E3%82%B0%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6",
          "aria-label": "このブログについて permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`このブログについて`}</h2>
      <p>{`以下の構成で作成されています。`}</p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.gatsbyjs.org/",
            "target": "_blank",
            "rel": "nofollow noopener"
          }}>{`Gatsby`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.typescriptlang.org/",
            "target": "_blank",
            "rel": "nofollow noopener"
          }}>{`TypeScript`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://mdxjs.com/",
            "target": "_blank",
            "rel": "nofollow noopener"
          }}>{`MDX`}</a></li>
      </ul>
      <h3 {...{
        "id": "css",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#css",
          "aria-label": "css permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`CSS`}</h3>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://emotion.sh",
            "target": "_blank",
            "rel": "nofollow noopener"
          }}>{`Emotion`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://tailwindcss.com/",
            "target": "_blank",
            "rel": "nofollow noopener"
          }}>{`Tailwind CSS`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://postcss.org/",
            "target": "_blank",
            "rel": "nofollow noopener"
          }}>{`PostCSS`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://purgecss.com/",
            "target": "_blank",
            "rel": "nofollow noopener"
          }}>{`PurgeCSS`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://ress-css.surge.sh/",
            "target": "_blank",
            "rel": "nofollow noopener"
          }}>{`ress`}</a></li>
      </ul>
      <p>{`TailwindCSSで表現できない部分のみEmotionで記述しています。`}</p>
      <h3 {...{
        "id": "font",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#font",
          "aria-label": "font permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Font`}</h3>
      <p>{`すべてに`}<a parentName="p" {...{
          "href": "https://fonts.google.com/specimen/Karla",
          "target": "_blank",
          "rel": "nofollow noopener"
        }}>{`Karla`}</a>{`を適用しています。`}</p>
      <h2 {...{
        "id": "書いてる人",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h2" {...{
          "href": "#%E6%9B%B8%E3%81%84%E3%81%A6%E3%82%8B%E4%BA%BA",
          "aria-label": "書いてる人 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`書いてる人`}</h2>
      <p>{`フロントエンドエンジニアです。`}</p>
      <h3 {...{
        "id": "スキル",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#%E3%82%B9%E3%82%AD%E3%83%AB",
          "aria-label": "スキル permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`スキル`}</h3>
      <ul>
        <li parentName="ul">{`React`}</li>
        <li parentName="ul">{`Vue`}</li>
        <li parentName="ul">{`JavaScript`}</li>
        <li parentName="ul">{`TypeScript`}</li>
      </ul>
      <h2 {...{
        "id": "アカウント",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h2" {...{
          "href": "#%E3%82%A2%E3%82%AB%E3%82%A6%E3%83%B3%E3%83%88",
          "aria-label": "アカウント permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`アカウント`}</h2>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://github.com/dnrsm",
            "target": "_blank",
            "rel": "nofollow noopener"
          }}>{`GitHub`}</a>
          <ul parentName="li">
            <li parentName="ul">{`メールアドレスを記載しています`}</li>
          </ul>
        </li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://qiita.com/dnrsm",
            "target": "_blank",
            "rel": "nofollow noopener"
          }}>{`Qiita`}</a>
          <ul parentName="li">
            <li parentName="ul">{`入門的なものや備忘録のような記事を書いています`}</li>
          </ul>
        </li>
      </ul>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      